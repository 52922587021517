import React from "react";
import Helmet from "react-helmet";
import Header from "../components/header";
import Layout from "../components/layout";

import experience from "./images/experience.svg";
import technology from "./images/technology.svg";

import mukesh from "./images/team/mukesh@3x.jpg";
import raj from "./images/team/pandeyraj@3x.jpg";
import vikash from "./images/team/vikash@3x.jpg";
import sanket from "./images/team/sanket.webp";
import rohit from "./images/team/rohit@3x.jpg";
import WithProviders from "../components/WithProviders";
import { Message } from "../lib/getMessage";

const Member = (props) => (
  <article className="member media media--vertical tc member-margin">
    <div className="media-img">
      <div className="avatar">
        <img
          src={props.avatar}
          alt={props.name}
          width="125"
          height="125"
          loading="lazy"
        />
      </div>
    </div>
    <div className="media-details">
      <h1 className={props.description ? "flex-row" : "flex-column"}>
        <span className="member-name">{props.name}</span>
        <p>{props.designation}</p>
      </h1>
      {props.description && <p>{props.description}</p>}
    </div>
  </article>
);

const AboutUs = (props) => {
  const { language, updateLanguage, direction } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout direction={direction} language={language} location={props.location}>
      <div>
        <Helmet title="About ZopSmart" />
        <Header
          current="about"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="about">
          <section
            className={`about-banner ${isDirectionRTL && "about-banner-rtl"}`}
          >
            <div className={`wrap ${isDirectionRTL && "wrap-rtl"}`}>
              <h1 className="">
                <Message dictKey="footer.about.us" />
              </h1>
              <p className="">
                <Message dictKey="about.us.description" />
              </p>
            </div>
          </section>
          <section className="about-intro">
            <section className="media media--vertical tc">
              <div
                className="media-img"
                style={{ backgroundImage: `url(${experience})` }}
              />
              <div className="media-details">
                <p>
                  <span className="h2">
                    <Message dictKey="our.expert" />
                  </span>
                  <Message dictKey="our.experties.description" />
                </p>
              </div>
            </section>
            <section className="media media--vertical tc">
              <div
                className="media-img"
                style={{ backgroundImage: `url(${technology})` }}
              />
              <div className="media-details">
                <p>
                  <span className="h2">
                    <Message dictKey="our.solution" />
                  </span>
                  <Message dictKey="our.solution.description" />
                </p>
              </div>
            </section>
          </section>
          <section className="about-team" id="team">
            <p className="tc">
              <span className="h1">
                <Message dictKey="our.team" />
              </span>
              <Message dictKey="our.team.description" />
            </p>
            <hr />
            <section>
              <section className="core team">
                <div className="wrap">
                  <Member
                    avatar={mukesh}
                    name={<Message dictKey="mukesh" />}
                    designation={<Message dictKey="mukesh.designation" />}
                    description={<Message dictKey="mukesh.description" />}
                  />
                  <Member
                    avatar={raj}
                    name={<Message dictKey="raj" />}
                    designation={<Message dictKey="raj.designation" />}
                    description={<Message dictKey="raj.description" />}
                  />
                  <Member
                    avatar={vikash}
                    name={<Message dictKey="vikash" />}
                    designation={<Message dictKey="vikash.designation" />}
                    description={<Message dictKey="vikash.description" />}
                  />
                  <Member
                    avatar={sanket}
                    name={<Message dictKey="sanket" />}
                    designation={<Message dictKey="sanket.designation" />}
                    description={<Message dictKey="sanket.description" />}
                  />
                  <Member
                    avatar={rohit}
                    name={<Message dictKey="rohit" />}
                    designation={<Message dictKey="rohit.designation" />}
                    description={<Message dictKey="rohit.description" />}
                  />
                </div>
              </section>
            </section>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(AboutUs);
